import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { HeaderConfig, HeaderLinkData, HeaderListData, SiteMetaConfig } from "../config/config-data"
import LogoIcon from "/static/assets/icon.svg"
import "../styles/header.css"


const Header = () => {

  const [setIsOpen,buttonIsOpen] = React.useState("close");
  const openButton = () => {
    buttonIsOpen("open");
  };
  const closeButton = () => {
    buttonIsOpen("closing");
    setTimeout(() => {
      buttonIsOpen("close");
    }, 300); // Put the TimeOut time as same as the closing animation time
  };

  React.useEffect(() => {
    if (setIsOpen === "open") {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [setIsOpen]);

  return (
    <nav id="header_1662795513601" className="Nav_Bar">
      <div id="header_1662795515088" style={{margin: "calc(var(--Text_Size_Five)/2)"}} className="Nav_Items">
        <div id="header_1662795516608" className="Nav_Brand">
          {HeaderConfig.navigation_brand_logo &&
          <Link id="header_1662795522504" className="Nav_Logo" exact="true" to="/" activeClassName="active">
            <img style={{height: "var(--Navbar_Logo_Size)"}} src={LogoIcon} loading="eager" alt="Our Logo" title="Our Logo" />
          </Link>
          }
          {HeaderConfig.navigation_brand_name &&
          <Link id="header_1662795524031" style={{color:"var(--PrimaryBrand)"}} exact="true" to="/" activeClassName="active" className="Nav_Name" title={SiteMetaConfig.site_name}>
            {SiteMetaConfig.site_name}
          </Link>
          }
        </div>
        <svg style={{height: "var(--Text_Size_Three)"}} xmlns="http://www.w3.org/2000/svg" alt="Nav Menu Button" className="Nav_Menu_Button" onClick={openButton} onKeyDown={openButton} viewBox="0 0 448 512"><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/></svg>
        <div id="header_1662795526352" className={`${setIsOpen === "open" ? "Nav_Overlay" : ""}${setIsOpen === "closing" ? "Nav_Overlay closing" : ""}${setIsOpen === "close" ? "Nav_Overlay close" : ""}`}>
          <svg xmlns="http://www.w3.org/2000/svg" className="Nav_Close_Button" onClick={closeButton} onKeyDown={closeButton} alt="Nav Close Button" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
          <div id="header_1662795527537" className="Nav_Container">
            <div id="header_1663184156098" className="Nav_List">
              {HeaderListData.map(({ navigation_list_name, navigation_list_items }) => (navigation_list_name &&
                <details key={navigation_list_name} className="Nav_Details">
                  <summary className="Nav_Summary">{navigation_list_name}</summary>
                  <div className="Nav_Details_Container Nav_List">
                    {navigation_list_items.map(({ navigation_link_label, navigation_page_link, navigation_url_link, navigation_url_new_tab }) => (
                      navigation_link_label
                      ?
                      navigation_page_link
                      ?
                      <Link key={"list-page"+navigation_link_label} activeClassName="active" exact="true" to={navigation_page_link} title={navigation_link_label}>{navigation_link_label}</Link>
                      :
                      navigation_url_link
                      ?
                      <a key={"list-url"+navigation_link_label} target={navigation_url_new_tab && "_blank"} href={navigation_url_link}>{navigation_link_label}</a>
                      :
                      ""
                      :
                      ""
                    ))}
                  </div>
                </details>
              ))}
              {HeaderLinkData.map(({ navigation_link_label, navigation_page_link, navigation_url_link, navigation_url_new_tab }) => (
                navigation_link_label
                ?
                navigation_page_link
                ?
                <Link key={"list-page"+navigation_link_label} activeClassName="active" exact="true" to={navigation_page_link} title={navigation_link_label}>{navigation_link_label}</Link>
                :
                navigation_url_link
                ?
                <a key={"list-url"+navigation_link_label} target={navigation_url_new_tab && "_blank"} href={navigation_url_link}>{navigation_link_label}</a>
                :
                ""
                :
                ""
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header
